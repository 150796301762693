var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'unselectable-text':
      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
  }},[(_vm.isLoading)?_c('div',[_c('b-card',[_c('b-skeleton',{attrs:{"animation":"","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"70%"}})],1)],1):_c('div',{staticClass:"question-container"},[(_vm.allows_crud)?_c('div',{staticClass:"question-move px-1 handle",class:{ 'grabbable-cursor': _vm.allows_crud }},[_c('b-icon',{staticClass:"move-icon",attrs:{"icon":"arrows-expand","scale":"1.3"}})],1):_vm._e(),_c('div',{staticClass:"container-content-question"},[_c('div',{staticClass:"container-header"},[_c('div',{staticClass:"container-body-card"},[_c('div',{staticClass:"question-body max-width-print max-width d-flex"},[_c('div',{staticClass:"row-div"},[_c('strong',[(_vm.testSegment)?_c('span',[_vm._v(_vm._s(_vm.testSegment.order)+".")]):_vm._e(),_vm._v(_vm._s(_vm.Question.order)+" ")])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                `${
                  _vm.allows_crud && !_vm.Question.is_locked
                    ? `Editar el ${_vm.$getVisibleNames(
                        'manual.test.enunciado',
                        false,
                        'Enunciado'
                      )}`
                    : ''
                }`
              ),expression:"\n                `${\n                  allows_crud && !Question.is_locked\n                    ? `Editar el ${$getVisibleNames(\n                        'manual.test.enunciado',\n                        false,\n                        'Enunciado'\n                      )}`\n                    : ''\n                }`\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"w-100 rich-text-content",class:{
                'title-container': _vm.allows_crud && !_vm.Question.is_locked,
              },domProps:{"innerHTML":_vm._s(_vm.Question.title)},on:{"click":function($event){return _vm.showModalQuestion()}}})]),_c('div',{class:{ 'container-buttons-allow-cruds': _vm.allows_crud }},[(
                !_vm.Question.is_locked &&
                _vm.allows_crud &&
                _vm.user &&
                !_vm.user.groups.includes(6)
              )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:('Bloquear Pregunta'),expression:"'Bloquear Pregunta'",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.changeStateQuestion()}}},[_c('b-icon-unlock-fill')],1):_vm._e(),(
                _vm.Question.is_locked &&
                _vm.allows_crud &&
                _vm.user &&
                !_vm.user.groups.includes(6)
              )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                'Desbloquear Pregunta '
              ),expression:"\n                'Desbloquear Pregunta '\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.changeStateQuestion()}}},[_c('b-icon-lock-fill')],1):_vm._e(),(_vm.allows_crud && !_vm.Question.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:('Editar Pregunta'),expression:"'Editar Pregunta'",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn mr-1 p-0",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":_vm.openModalQuestion}},[_c('b-icon-pencil-square')],1):_vm._e(),(!_vm.copy_button_tem_check)?[(_vm.allows_crud && !_vm.Question.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Duplicar Pregunta'
                ),expression:"\n                  'Duplicar Pregunta'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn mr-1 p-0",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.copyQuestion}},[_c('b-icon',{attrs:{"icon":"files"}})],1):_vm._e()]:[_c('b-button',{staticClass:"edit-btn mr-1 p-0",attrs:{"variant":"secondary","size":"sm","disabled":""}},[_c('b-icon',{attrs:{"icon":"files"}})],1)],(_vm.allows_crud && !_vm.Question.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:('Eliminar Pregunta'),expression:"'Eliminar Pregunta'",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"delete-btn p-0",attrs:{"variant":"danger","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.askForDeleteQuestion()}}},[_c('b-icon-trash')],1):_vm._e()],2)])]),_c('div',{staticClass:"container-body"},[(_vm.institution && _vm.institution.internal_use_id != 'duoc_uc')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `${
              _vm.allows_crud && !_vm.Question.is_locked ? 'Editar Descripción' : ''
            }`
          ),expression:"\n            `${\n              allows_crud && !Question.is_locked ? 'Editar Descripción' : ''\n            }`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"description-question rich-text-content",class:{
            'description-container': _vm.allows_crud && !_vm.Question.is_locked,
            'unselectable-text':
              (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
          },domProps:{"innerHTML":_vm._s(_vm.Question.description)},on:{"click":function($event){return _vm.showModalDescriptionQuestion()}}}):_vm._e(),_c('div',{staticClass:"separator mb-2 mt-1"}),(_vm.Question.question_type == 1)?_c('div',{staticClass:"limit-words-section"},[_c('LimitQuestion',{attrs:{"Question":_vm.Question,"QuestionScore":_vm.EvaluateeQuestionScore,"allows_crud":_vm.allows_crud && !_vm.Question.is_locked,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id}})],1):(_vm.Question.question_type == 2)?_c('div',[_c('SelectQuestionOptions',{staticClass:"ml-3",attrs:{"Question":_vm.Question,"QuestionScore":_vm.EvaluateeQuestionScore,"allows_crud":_vm.allows_crud && !_vm.Question.is_locked,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id,"is_published":_vm.is_published}})],1):(_vm.Question.question_type == 3)?_c('div',[_c('FillingQuestion',{attrs:{"Question":_vm.Question,"allows_crud":_vm.allows_crud && !_vm.Question.is_locked,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test}})],1):(_vm.Question.question_type == 4)?_c('div',[_c('MatchingQuestion',{attrs:{"Question":_vm.Question,"container_instrument_id":_vm.container_instrument_id,"QuestionScore":_vm.EvaluateeQuestionScore,"allows_crud":_vm.allows_crud && !_vm.Question.is_locked,"preview_evaluation":_vm.preview_evaluation,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id}})],1):_vm._e(),(
            _vm.evaluator_view &&
            _vm.user_id &&
            _vm.Question.linked_rubric_criteria != null
          )?_c('div',[_c('RubricsContainer',{staticClass:"ml-4 mr-4",attrs:{"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"container_instrument_id":_vm.container_instrument_id,"instrument_id":_vm.Question.test,"allows_crud":false,"preview_evaluation":_vm.preview_evaluation,"evaluator_view":_vm.evaluator_view,"user_id":_vm.user_id,"closedEvaluationScore":_vm.closedEvaluationScore,"requests_within_the_component":false,"filter_rubric_instance":_vm.Question.linked_rubric_criteria}})],1):_vm._e(),_c('div',{staticClass:"separator mb-3",class:{ 'mt-2': _vm.Question.question_type == 2 }})]),_c('div',[_c('div',{staticClass:"container-footer"},[_c('div',{staticClass:"d-flex"},[(!_vm.checked_score)?_c('div',{staticClass:"score-input-container"},[_vm._m(0),(_vm.allows_crud && !_vm.Question.is_locked)?_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
                  `${
                    _vm.questionsMaxScore > _vm.test.max_score
                      ? `El puntaje de las preguntas es mayor que el puntaje de la ${_vm.$getVisibleNames(
                          'evaluations2.test',
                          true,
                          'Instrumento'
                        )}`
                      : _vm.questionsMaxScore < _vm.test.max_score
                      ? `El puntaje de las preguntas es menor que el puntaje de la ${_vm.$getVisibleNames(
                          'evaluations2.test',
                          true,
                          'Instrumento'
                        )}`
                      : ''
                  }`
                ),expression:"\n                  `${\n                    questionsMaxScore > test.max_score\n                      ? `El puntaje de las preguntas es mayor que el puntaje de la ${$getVisibleNames(\n                          'evaluations2.test',\n                          true,\n                          'Instrumento'\n                        )}`\n                      : questionsMaxScore < test.max_score\n                      ? `El puntaje de las preguntas es menor que el puntaje de la ${$getVisibleNames(\n                          'evaluations2.test',\n                          true,\n                          'Instrumento'\n                        )}`\n                      : ''\n                  }`\n                ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],staticClass:"mr-3",class:{
                  'score-input-warning': _vm.questionsMaxScore > _vm.test.max_score,
                  'score-input-warning-minor':
                    _vm.questionsMaxScore < _vm.test.max_score,
                },attrs:{"type":"number","min":"0"},on:{"input":_vm.patchQuestionScore},model:{value:(_vm.Question.max_score),callback:function ($$v) {_vm.$set(_vm.Question, "max_score", $$v)},expression:"Question.max_score"}}):_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.Question.max_score))])],1):_vm._e(),(!_vm.checked_weightning)?_c('div',{staticClass:"d-flex mr-2"},[_c('strong',{staticClass:"mr-2"},[_vm._v("Ponderación:")]),(
                  _vm.institution &&
                  _vm.institution.internal_use_id == 'duoc_uc' &&
                  _vm.Question.linked_rubric_criteria != null &&
                  _vm.rubricsCriteria &&
                  _vm.allows_crud
                )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-info",value:(
                  `${
                    _vm.questionsWeighingRubricCriteria > _vm.rubricsCriteria.weighing
                      ? 'Se excedió del ' +
                        _vm.rubricsCriteria.weighing +
                        '% asignado en el IE, aproximadamente en: ' +
                        _vm.suggestedScore * -1
                      : _vm.questionsWeighingRubricCriteria <
                        _vm.rubricsCriteria.weighing
                      ? 'Actualmente tiene disponible ' +
                        parseFloat(
                          (
                            _vm.rubricsCriteria.weighing -
                            _vm.questionsWeighingRubricCriteria
                          ).toFixed(2)
                        ) +
                        '% para el IE seleccionado, en puntaje es: ' +
                        _vm.suggestedScore
                      : ''
                  }`
                ),expression:"\n                  `${\n                    questionsWeighingRubricCriteria > rubricsCriteria.weighing\n                      ? 'Se excedió del ' +\n                        rubricsCriteria.weighing +\n                        '% asignado en el IE, aproximadamente en: ' +\n                        suggestedScore * -1\n                      : questionsWeighingRubricCriteria <\n                        rubricsCriteria.weighing\n                      ? 'Actualmente tiene disponible ' +\n                        parseFloat(\n                          (\n                            rubricsCriteria.weighing -\n                            questionsWeighingRubricCriteria\n                          ).toFixed(2)\n                        ) +\n                        '% para el IE seleccionado, en puntaje es: ' +\n                        suggestedScore\n                      : ''\n                  }`\n                ",modifiers:{"top":true,"noninteractive":true,"v-info":true}}],class:{
                  'text-danger':
                    _vm.questionsWeighingRubricCriteria !=
                    _vm.rubricsCriteria.weighing,
                }},[_vm._v(" "+_vm._s(_vm.questionsWeighing)+"% ")]):_c('div',[_vm._v(_vm._s(_vm.questionsWeighing)+"%")])]):_vm._e(),_c('div',{staticClass:"question-type-container"},[_vm._m(1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
                  `${
                    (!_vm.enable_question_types.includes(
                      _vm.Question.question_type
                    ) ||
                      (_vm.Question.question_type == 2 &&
                        !_vm.enable_question_sub_types.includes(
                          _vm.Question.subtype
                        ))) &&
                    _vm.allows_crud
                      ? 'El Tipo de pregunta no pertenece a la ' +
                        _vm.$getVisibleNames(
                          'teaching.evaluationsituation',
                          false,
                          'Situación Evaluativa'
                        ) +
                        '.'
                      : ''
                  }`
                ),expression:"\n                  `${\n                    (!enable_question_types.includes(\n                      Question.question_type\n                    ) ||\n                      (Question.question_type == 2 &&\n                        !enable_question_sub_types.includes(\n                          Question.subtype\n                        ))) &&\n                    allows_crud\n                      ? 'El Tipo de pregunta no pertenece a la ' +\n                        $getVisibleNames(\n                          'teaching.evaluationsituation',\n                          false,\n                          'Situación Evaluativa'\n                        ) +\n                        '.'\n                      : ''\n                  }`\n                ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],class:{
                  'text-danger':
                    (!_vm.enable_question_types.includes(
                      _vm.Question.question_type
                    ) ||
                      (_vm.Question.question_type == 2 &&
                        !_vm.enable_question_sub_types.includes(
                          _vm.Question.subtype
                        ))) &&
                    _vm.allows_crud,
                }},[_vm._v(" "+_vm._s(_vm.questionTypes())+" "),(_vm.Question.question_type == 2)?[(_vm.Question.subtype == 2)?_c('span',[_vm._v(" Múltiple")]):(_vm.Question.subtype == 1)?_c('span',[_vm._v(" Simple")]):_vm._e()]:_vm._e()],2)])]),(_vm.evaluator_view && _vm.user_id)?[_c('div',{staticClass:"d-flex"},[(
                  _vm.Question.question_type == 2 || _vm.Question.question_type == 4
                )?_c('div',{staticClass:"score-input-container ml-3"},[_c('div',{staticClass:"mr-1"},[_c('strong',[_vm._v("Puntaje Automático: ")]),_vm._v(" "+_vm._s(_vm.evaluatee_question_score.automated_score != null ? _vm.evaluatee_question_score.automated_score : 0)+" ")])]):_vm._e(),_c('div',{staticClass:"score-input-container ml-3"},[_vm._m(2),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"mr-3",class:{
                      'border-warning text-warning':
                        _vm.evaluatee_question_score.manual_score >
                        _vm.Question.max_score,
                      'border-danger text-danger':
                        _vm.evaluatee_question_score.manual_score < 0,
                    },staticStyle:{"max-width":"80px"},attrs:{"id":`tooltip-target-manual_score-${_vm.Question.id}-${
                      _vm.Question.order
                    }-${_vm.testSegment ? _vm.testSegment.order : ''}`,"disabled":!_vm.closedEvaluationScore,"type":"number","min":"0"},on:{"input":_vm.patchEvaluateeScore},model:{value:(_vm.evaluatee_question_score.manual_score),callback:function ($$v) {_vm.$set(_vm.evaluatee_question_score, "manual_score", $$v)},expression:"evaluatee_question_score.manual_score"}}),(_vm.evaluatee_question_score.manual_score < 0)?_c('b-tooltip',{attrs:{"target":`tooltip-target-manual_score-${_vm.Question.id}-${
                      _vm.Question.order
                    }-${_vm.testSegment ? _vm.testSegment.order : ''}`,"variant":"danger","triggers":"hover"}},[_vm._v(" El puntaje no debe ser menor que 0 ")]):(
                      _vm.evaluatee_question_score.manual_score >
                      _vm.Question.max_score
                    )?_c('b-tooltip',{attrs:{"target":`tooltip-target-manual_score-${_vm.Question.id}-${
                      _vm.Question.order
                    }-${_vm.testSegment ? _vm.testSegment.order : ''}`,"variant":"warning","triggers":"hover"}},[_vm._v(" El puntaje asignado es mayor que el de la pregunta ")]):_vm._e()],1)]),_c('div',{staticClass:"score-input-container ml-1 mr-1"},[(
                    _vm.evaluator_view &&
                    _vm.closedEvaluationScore &&
                    (_vm.evaluatee_question_score.feedback == null ||
                      _vm.evaluatee_question_score.feedback.trim().length == 0)
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Agregar Retroalimentación'
                  ),expression:"\n                    'Agregar Retroalimentación'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.showModalQuestionFeedback()}}},[_c('b-icon',{attrs:{"icon":"chat-left-text-fill"}})],1):_vm._e()],1)])]:_vm._e(),(
              !_vm.evaluator_view &&
              !_vm.evaluatee_view &&
              _vm.allows_crud &&
              !_vm.Question.is_locked &&
              [1].includes(_vm.Question.question_type) &&
              _vm.Question.proposed_answer.trim().length == 0
            )?[_c('div',{staticClass:"d-flex noprint"},[_c('div',{staticClass:"score-input-container ml-3 mr-1"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Añadir Respuesta Correcta'
                  ),expression:"\n                    'Añadir Respuesta Correcta'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.showModalProposedAnswerQuestion()}}},[_c('b-icon',{attrs:{"icon":"file-check-fill"}})],1)],1)])]:_vm._e(),(
              !_vm.evaluator_view &&
              !_vm.evaluatee_view &&
              _vm.allows_crud &&
              !_vm.Question.is_locked &&
              [2].includes(_vm.Question.question_type)
            )?[_c('div',{staticClass:"d-flex noprint"},[(_vm.Question.correct_alternative_message.trim().length == 0)?_c('div',{staticClass:"score-input-container ml-3"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Añadir Respuesta Correcta'
                  ),expression:"\n                    'Añadir Respuesta Correcta'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.showModalCorrectMessageQuestion()}}},[_c('b-icon',{attrs:{"icon":"file-check-fill"}})],1)],1):_vm._e(),(
                  _vm.Question.incorrect_alternative_message.trim().length == 0
                )?_c('div',{staticClass:"score-input-container mr-1"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Añadir Respuesta Incorrecta'
                  ),expression:"\n                    'Añadir Respuesta Incorrecta'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.showModalIncorrectMessageQuestion()}}},[_c('b-icon',{attrs:{"icon":"file-excel-fill"}})],1)],1):_vm._e()])]:_vm._e()],2),(
            (!_vm.evaluator_view &&
              !_vm.evaluatee_view &&
              _vm.allows_crud &&
              [1].includes(_vm.Question.question_type) &&
              _vm.Question.proposed_answer.trim().length != 0) ||
            (_vm.evaluator_view &&
              !_vm.evaluatee_view &&
              [1].includes(_vm.Question.question_type) &&
              _vm.Question.proposed_answer.trim().length != 0)
          )?_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                      `collapse-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`
                    ),expression:"\n                      `collapse-${Question.test}-${Question.question_type}-${Question.id}`\n                    "}],attrs:{"block":"","tabindex":"-1","variant":"info"}},[_vm._v("Respuesta Correcta")]),(!_vm.evaluator_view && !_vm.evaluatee_view)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Limpiar Respuesta Correcta`
                    ),expression:"\n                      `Limpiar Respuesta Correcta`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"danger"},on:{"click":_vm.clearCorrectAnswer}},[_c('b-icon-trash')],1):_vm._e()],1)]),_c('b-collapse',{attrs:{"id":`collapse-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`,"visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"p-0"},[_c('b-card-text',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `${
                          _vm.allows_crud && !_vm.Question.is_locked
                            ? `Editar la Respuesta Correcta`
                            : ''
                        }`
                      ),expression:"\n                        `${\n                          allows_crud && !Question.is_locked\n                            ? `Editar la Respuesta Correcta`\n                            : ''\n                        }`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"w-100 rich-text-content pl-2 pr-2 pt-2 pb-3",class:{
                        'title-container': _vm.allows_crud && !_vm.Question.is_locked,
                      },domProps:{"innerHTML":_vm._s(_vm.Question.proposed_answer)},on:{"click":function($event){return _vm.showModalProposedAnswerQuestion()}}})])],1)],1)],1)],1)]):_vm._e(),(
            [2].includes(_vm.Question.question_type) &&
            _vm.Question.correct_alternative_message.trim().length != 0 &&
            ((!_vm.evaluator_view && !_vm.evaluatee_view && _vm.allows_crud) ||
              (_vm.evaluator_view && !_vm.evaluatee_view) ||
              (_vm.evaluatee_view && _vm.is_published && _vm.finished_test))
          )?_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                      `collapse-correct_alternative_message-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`
                    ),expression:"\n                      `collapse-correct_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`\n                    "}],attrs:{"block":"","tabindex":"-1","variant":"info"}},[_vm._v("Respuesta Correcta")]),(!_vm.evaluator_view && !_vm.evaluatee_view)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Limpiar Respuesta Correcta`
                    ),expression:"\n                      `Limpiar Respuesta Correcta`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"danger"},on:{"click":_vm.clearCorrectMessage}},[_c('b-icon-trash')],1):_vm._e()],1)]),_c('b-collapse',{attrs:{"id":`collapse-correct_alternative_message-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`,"visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"p-0"},[_c('b-card-text',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `${
                          _vm.allows_crud && !_vm.Question.is_locked
                            ? `Editar la Respuesta Correcta`
                            : ''
                        }`
                      ),expression:"\n                        `${\n                          allows_crud && !Question.is_locked\n                            ? `Editar la Respuesta Correcta`\n                            : ''\n                        }`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"w-100 rich-text-content pl-2 pr-2 pt-2 pb-3",class:{
                        'title-container': _vm.allows_crud && !_vm.Question.is_locked,
                      },domProps:{"innerHTML":_vm._s(_vm.Question.correct_alternative_message)},on:{"click":function($event){return _vm.showModalCorrectMessageQuestion()}}})])],1)],1)],1)],1)]):_vm._e(),(
            [2].includes(_vm.Question.question_type) &&
            _vm.Question.incorrect_alternative_message.trim().length != 0 &&
            ((!_vm.evaluator_view && !_vm.evaluatee_view && _vm.allows_crud) ||
              (_vm.evaluator_view && !_vm.evaluatee_view) ||
              (_vm.evaluatee_view &&
                _vm.is_published &&
                _vm.finished_test &&
                ((_vm.evaluatee_question_score &&
                  ((_vm.evaluatee_question_score.final_score != null &&
                    _vm.evaluation.final_score != _vm.Question.max_score) ||
                    (_vm.evaluatee_question_score.final_score == null &&
                      _vm.evaluatee_question_score.manual_score != null &&
                      _vm.evaluatee_question_score.manual_score !=
                        _vm.Question.max_score) ||
                    (_vm.evaluatee_question_score.final_score == null &&
                      _vm.evaluatee_question_score.manual_score == null &&
                      _vm.evaluatee_question_score.automated_score != null &&
                      _vm.evaluatee_question_score.automated_score !=
                        _vm.Question.max_score))) ||
                  isNaN(_vm.evaluatee_question_score))))
          )?_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                      `collapse-incorrect_alternative_message-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`
                    ),expression:"\n                      `collapse-incorrect_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`\n                    "}],attrs:{"block":"","tabindex":"-1","variant":"info"}},[_vm._v("Respuesta Incorrecta")]),(!_vm.evaluator_view && !_vm.evaluatee_view)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Limpiar Respuesta Incorrecta`
                    ),expression:"\n                      `Limpiar Respuesta Incorrecta`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"danger"},on:{"click":_vm.clearIncorrectMessage}},[_c('b-icon-trash')],1):_vm._e()],1)]),_c('b-collapse',{attrs:{"id":`collapse-incorrect_alternative_message-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`,"visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"p-0"},[_c('b-card-text',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `${
                          _vm.allows_crud && !_vm.Question.is_locked
                            ? `Editar la Respuesta Incorrecta`
                            : ''
                        }`
                      ),expression:"\n                        `${\n                          allows_crud && !Question.is_locked\n                            ? `Editar la Respuesta Incorrecta`\n                            : ''\n                        }`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"w-100 rich-text-content pl-2 pr-2 pt-2 pb-3",class:{
                        'title-container': _vm.allows_crud && !_vm.Question.is_locked,
                      },domProps:{"innerHTML":_vm._s(_vm.Question.incorrect_alternative_message)},on:{"click":function($event){return _vm.showModalIncorrectMessageQuestion()}}})])],1)],1)],1)],1)]):_vm._e(),(
            (_vm.evaluator_view &&
              _vm.evaluatee_question_score.feedback != null &&
              _vm.evaluatee_question_score.feedback.trim().length != 0) ||
            (!_vm.evaluator_view &&
              _vm.evaluatee_view &&
              _vm.is_published &&
              _vm.finished_test &&
              _vm.evaluatee_question_score.feedback != null &&
              _vm.evaluatee_question_score.feedback.trim().length != 0)
          )?_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                      `collapse-feedback-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`
                    ),expression:"\n                      `collapse-feedback-${Question.test}-${Question.question_type}-${Question.id}`\n                    "}],attrs:{"block":"","tabindex":"-1","variant":"info"}},[_vm._v("Retroalimentación")]),(!_vm.evaluatee_view && _vm.closedEvaluationScore)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Limpiar Retroalimencación`
                    ),expression:"\n                      `Limpiar Retroalimencación`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"danger"},on:{"click":_vm.clearFeedbackTeacher}},[_c('b-icon-trash')],1):_vm._e()],1)]),_c('b-collapse',{attrs:{"id":`collapse-feedback-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}`,"visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"p-0"},[_c('b-card-text',[(!_vm.evaluatee_view && _vm.closedEvaluationScore)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `${
                          _vm.allows_crud && !_vm.Question.is_locked
                            ? `Editar la Respuesta Correcta`
                            : ''
                        }`
                      ),expression:"\n                        `${\n                          allows_crud && !Question.is_locked\n                            ? `Editar la Respuesta Correcta`\n                            : ''\n                        }`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"title-container w-100 rich-text-content pl-2 pr-2 pt-2 pb-3",domProps:{"innerHTML":_vm._s(_vm.evaluatee_question_score.feedback)},on:{"click":function($event){return _vm.showModalQuestionFeedback()}}}):(!_vm.evaluatee_view && !_vm.closedEvaluationScore)?_c('div',{staticClass:"w-100 rich-text-content pl-2 pr-2 pt-2 pb-3",domProps:{"innerHTML":_vm._s(_vm.evaluatee_question_score.feedback)}}):_vm._e(),(
                        _vm.evaluatee_view &&
                        _vm.is_published &&
                        _vm.finished_test &&
                        _vm.evaluatee_question_score.feedback != null &&
                        _vm.evaluatee_question_score.feedback.trim().length != 0
                      )?_c('div',[_c('div',{staticClass:"rich-text-content p-1 pl-2",domProps:{"innerHTML":_vm._s(_vm.evaluatee_question_score.feedback)}})]):_vm._e()])],1)],1)],1)],1)]):_vm._e()])])]),_c('b-modal',{attrs:{"id":`edit-title-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
      'manual.test.enunciado',
      false,
      'Enunciado'
    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.Question,"Text":_vm.Question.title,"hide_title":true},on:{"save":_vm.patchQuestionTitle,"close":function($event){return _vm.$bvModal.hide(
          `edit-title-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`edit-description-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":"Modificar Descripción","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.Question,"Text":_vm.Question.description,"permit_blank":true,"hide_title":true},on:{"save":_vm.patchQuestionDescription,"close":function($event){return _vm.$bvModal.hide(
          `edit-description-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`edit-proposed_answer-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":"Modificar la Respuesta Correcta","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.Question,"Text":_vm.Question.proposed_answer,"permit_blank":true,"hide_title":true},on:{"save":_vm.patchQuestionProposedAnswer,"close":function($event){return _vm.$bvModal.hide(
          `edit-proposed_answer-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`edit-correct_alternative_message-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":"Modificar la Respuesta Correcta","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.Question,"Text":_vm.Question.correct_alternative_message,"permit_blank":true,"hide_title":true},on:{"save":_vm.patchQuestionCorrectMessage,"close":function($event){return _vm.$bvModal.hide(
          `edit-correct_alternative_message-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`edit-incorrect_alternative_message-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":"Modificar la Respuesta Incorrecta","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.Question,"Text":_vm.Question.incorrect_alternative_message,"permit_blank":true,"hide_title":true},on:{"save":_vm.patchQuestionIncorrectMessage,"close":function($event){return _vm.$bvModal.hide(
          `edit-incorrect_alternative_message-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`edit-feedback-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":"Modificar Retroalimentación","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.evaluatee_question_score,"Text":_vm.evaluatee_question_score.feedback,"permit_blank":true,"hide_title":true},on:{"save":_vm.patchFeedbackQuestion,"close":function($event){return _vm.$bvModal.hide(
          `edit-feedback-question-modal-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`modal-edit-question-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
      'evaluations2.redactionquestion',
      false,
      'Pregunta'
    )}`,"hide-footer":"","size":"lg","ignore-enforce-focus-selector":"#sidebar-right"},on:{"hide":_vm.hideModalQuestion}},[_c('NewQuestionForm',{attrs:{"test_id":_vm.Question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":_vm.Question,"evaluation_situations":_vm.evaluation_situations},on:{"closeModal":function($event){return _vm.$bvModal.hide(
          `modal-edit-question-${_vm.Question.test}-${_vm.Question.question_type}-${_vm.Question.id}-${_vm.container_instrument_id}`
        )}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-1"},[_c('strong',[_vm._v("Puntaje:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-type mr-2"},[_c('strong',[_vm._v(" Tipo de pregunta:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-1"},[_c('strong',[_vm._v("Puntaje Obtenido:")])])
}]

export { render, staticRenderFns }